import axios from "axios"
import JwtDecode from "jwt-decode"

axios.defaults.baseURL = process.env.API_URL
const tokenKey = "token"
const impersonateTokenKey = "impersonate-token"

const login = async (username, password) => {
  try {
    const response = await axios.post("/api/v1/auth/login", {
      username,
      password,
    })
    const { data: jwt } = response
    localStorage.setItem(tokenKey, jwt)
    return true
  } catch (error) {
    throw error
  }
}

const logout = () => {
  const { impersonated } = getCurrentUser() || {}
  impersonated
    ? localStorage.removeItem(impersonateTokenKey)
    : localStorage.removeItem(tokenKey)
}

const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(tokenKey)
    const impersonateJwt = localStorage.getItem(impersonateTokenKey)
    let impersonated = false
    let impersonateUsername = null
    let { roles, permissions, ...rest } = JwtDecode(jwt)
    if (impersonateJwt) {
      const {
        roles: impersonateRoles,
        permissions: impersonatePermissions,
        username,
      } = JwtDecode(impersonateJwt)
      roles = convertStringAndNullToArray(impersonateRoles)
      permissions = convertStringAndNullToArray(impersonatePermissions)
      impersonated = true
      impersonateUsername = username
    }
    return {
      ...rest,
      roles: convertStringAndNullToArray(roles),
      permissions: convertStringAndNullToArray(permissions),
      impersonated,
      impersonateUsername,
    }
  } catch (e) {
    return null
  }
}

const getBearerToken = () => {
  if (typeof window !== `undefined`) {
    return "Bearer " + localStorage.getItem(tokenKey)
  }
  return null
}

const hasAccessTo = permission => {
  const { permissions } = getCurrentUser() || {}
  return permissions ? permissions.indexOf(permission) !== -1 : false
}

const hasAccessToAny = permissionList => {
  return permissionList.some(hasAccessTo)
}
const hasAccessToAll = permissionList => {
  return permissionList.every(hasAccessTo)
}

const convertStringAndNullToArray = item => {
  if (Array.isArray(item)) return item
  if (typeof item === "string") return [item]
  return []
}

const isLoggedIn = async () => {
  if (getCurrentUser() !== null) {
    try {
      await refreshToken()
    } catch (error) {
      return false
    }
    return true
  }
  return false
}

const refreshToken = async clientCode => {
  let requestedClientCode = ""
  if (clientCode === undefined) {
    const { client_code: currentClientCode } = getCurrentUser()
    requestedClientCode = currentClientCode
  } else if (clientCode === "core") {
    requestedClientCode = ""
  } else {
    requestedClientCode = clientCode
  }
  try {
    const response = await axios.post(
      "/api/v1/auth/refresh",
      { clientCode: requestedClientCode },
      {
        headers: { Authorization: getBearerToken() },
      }
    )
    const { data: jwt } = response
    localStorage.setItem(tokenKey, jwt)
    return true
  } catch (error) {
    throw error
  }
}

const impersonateUser = async userId => {
  try {
    const response = await axios.post(
      "/api/v1/auth/impersonate",
      { userId },
      {
        headers: { Authorization: getBearerToken() },
      }
    )
    const { data: jwt } = response
    localStorage.setItem(impersonateTokenKey, jwt)
    return true
  } catch (error) {
    throw error
  }
}

const activateUserAccount = async (email, code, password) => {
  try {
    await axios.post("/api/v1/auth/activate", {
      email,
      code,
      password,
    })
    return true
  } catch (error) {
    throw error
  }
}

const forgotPassword = async email => {
  try {
    await axios.post("/api/v1/auth/forgot-password", {
      email,
    })
    return true
  } catch (error) {
    throw error
  }
}

const resetPassword = async (email, code) => {
  try {
    await axios.post("/api/v1/auth/reset-password", {
      email,
      code,
    })
    return true
  } catch (error) {
    throw error
  }
}

export {
  login,
  logout,
  getCurrentUser,
  getBearerToken,
  hasAccessTo,
  hasAccessToAny,
  hasAccessToAll,
  isLoggedIn,
  refreshToken,
  impersonateUser,
  activateUserAccount,
  forgotPassword,
  resetPassword,
}
