import React from "react"
import { Provider } from "react-redux"

import createStore from "./src/state/createStore"
import { makeServer } from "./fakeServer"

if (process.env.NODE_ENV === "development") {
  makeServer()
}
export default ({ element }) => {
  const store = createStore()
  return <Provider store={store}>{element}</Provider>
}
