import {
  createStore as reduxCreateStore,
  applyMiddleware,
  combineReducers,
} from "redux"
import { composeWithDevTools } from "remote-redux-devtools"
import thunk from "redux-thunk"
import { throttle } from "lodash"

import * as reducers from "./ducks"
import { saveState } from "./localStorage"

const composeEnhancers = composeWithDevTools({ realtime: true, port: 8001 })
const rootReducer = combineReducers(reducers)

const createStore = () => {
  let store
  if (process.env.NODE_ENV === "development") {
    store = reduxCreateStore(
      rootReducer,
      composeEnhancers(applyMiddleware(thunk))
    )
  } else {
    store = reduxCreateStore(rootReducer, applyMiddleware(thunk))
  }
  store.subscribe(
    throttle(() => {
      saveState(store.getState())
    }, 1000)
  )
  return store
}
export default createStore
