import { createSlice } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"

import { loadState } from "../../localStorage"
import { calculateAggregationInterval } from "../../../components/dashboard/shared/utilities"

const persistedState = loadState()

let timeRangeInitialState = {
  from: dayjs()
    .subtract(1, "week")
    .startOf("day")
    .toISOString(),
  to: dayjs()
    .endOf("day")
    .toISOString(),
  last: 604800,
  aggregationInterval: { days: 1 },
}
if (
  persistedState &&
  persistedState.dashboard &&
  persistedState.dashboard.timeRange
) {
  timeRangeInitialState = persistedState.dashboard.timeRange
  const { from, to, last } = persistedState.dashboard.timeRange
  timeRangeInitialState.aggregationInterval = calculateAggregationInterval(
    from,
    to,
    last
  )
}
const timeRange = createSlice({
  name: "timeRange",
  initialState: timeRangeInitialState,
  reducers: {
    setTimeRange: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

let autoRefreshInitialState = { uuid: uuidv4(), interval: 1800, freeze: true }
if (
  persistedState &&
  persistedState.dashboard &&
  persistedState.dashboard.autoRefresh
) {
  const { freeze, ...rest } = persistedState.dashboard.autoRefresh
  autoRefreshInitialState = rest
}
const autoRefresh = createSlice({
  name: "autoRefresh",
  initialState: autoRefreshInitialState,
  reducers: {
    setAutoRefreshInterval: (state, action) => {
      return { ...state, interval: action.payload }
    },
    refreshUuid: state => {
      return { ...state, uuid: uuidv4() }
    },
    freezeAutoRefresher: (state, action) => {
      return { ...state, freeze: action.payload }
    },
  },
})

let uiInitialState = { drawerOpen: true }
if (persistedState && persistedState.dashboard && persistedState.dashboard.ui) {
  uiInitialState = persistedState.dashboard.ui
}
const ui = createSlice({
  name: "ui",
  initialState: uiInitialState,
  reducers: {
    setUiDrawerOpen: (state, action) => {
      return { ...state, drawerOpen: action.payload }
    },
  },
})

const reducers = combineReducers({
  autoRefresh: autoRefresh.reducer,
  timeRange: timeRange.reducer,
  ui: ui.reducer,
})

export const { setTimeRange } = timeRange.actions
export const {
  setAutoRefreshInterval,
  refreshUuid,
  freezeAutoRefresher,
} = autoRefresh.actions
export const { setUiDrawerOpen } = ui.actions
export default reducers
