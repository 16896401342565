import dayjs from "dayjs"
import { isNil } from "lodash"
import axios from "axios"

import { getBearerToken, hasAccessTo } from "../../authentication"

axios.interceptors.request.use(config => {
  config.headers.Authorization = getBearerToken()
  config.baseURL = process.env.API_URL
  return config
})

const calculateAggregationInterval = (from, to, last) => {
  if (!last) {
    last = dayjs(dayjs(to) - dayjs(from)).unix()
  }
  if (last < 3600 + 1) {
    return { month: 0, weeks: 0, days: 0, hours: 0, minutes: 1 }
  } else if (last < 86400 + 1) {
    return { month: 0, weeks: 0, days: 0, hours: 1, minutes: 0 }
  } else if (last < 2592000 + 1) {
    return { month: 0, weeks: 0, days: 1, hours: 0, minutes: 0 }
  } else if (last < 7776000 + 1) {
    return { month: 0, weeks: 1, days: 0, hours: 0, minutes: 0 }
  } else {
    return { month: 1, weeks: 0, days: 0, hours: 0, minutes: 0 }
  }
}
const calculateVisualRange = timeRange => {
  if (timeRange.last) {
    return dayjs(new Date().toLocaleString())
      .subtract(timeRange.last, "second")
      .format("MM/DD/YYYY HH:mm:ss")
  }
  return dayjs(new Date(timeRange.from).toLocaleString()).format(
    "MM/DD/YYYY HH:mm:ss"
  )
}

const convertUtcToLocal = date => {
  return date === null
    ? ""
    : dayjs(new Date(date).toLocaleString()).format("MM/DD/YYYY HH:mm:ss")
}
const convertUtcToLocalWithoutZPostfix = date => {
  if (date.toString().charAt(-1) !== "Z") date += "Z"
  return dayjs(new Date(date).toLocaleString()).format("MM/DD/YYYY HH:mm:ss")
}

const getAccordionState = key => async e => {
  let savedValue = {}
  if (typeof window !== `undefined`) {
    savedValue = JSON.parse(localStorage.getItem("accordion-states")) || {}
  }
  for (const item of savedValue[key] || [0]) {
    await e.component.expandItem(item)
  }
}

const getFirstAccessibleIndex = items =>
  items.findIndex(item => hasAccessTo(item))

const getLocalStorageState = localStorageKey => {
  const state = localStorage.getItem(localStorageKey)
  return JSON.parse(state)
}

const setAccordionState = key => e => {
  const selectedItems = e.component.option("selectedItems")
  const selectedTemplates = selectedItems.map(item => {
    if (item?.template?.match(/\d{1,}/g)?.length > 0)
      return Number(item.template.match(/\d{1,}/g)[0])
    return 0
  })
  if (selectedTemplates.length > 0 && typeof window !== `undefined`) {
    const savedValue =
      JSON.parse(localStorage.getItem("accordion-states")) || {}
    savedValue[key] = selectedTemplates
    localStorage.setItem("accordion-states", JSON.stringify(savedValue))
  }
}

const setLocalStorageGridState = (localStorageKey, state) => {
  state.searchText = ""
  localStorage.setItem(localStorageKey, JSON.stringify(state))
}

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const timeRangeToParam = (timeRange, addQuestionMark = false) => {
  if (!timeRange) return ""
  const { from, to, last } = timeRange
  let params = addQuestionMark ? "?" : ""
  if (!isNil(last)) {
    params += `last=${last}`
  } else if (!isNil(from) && !isNil(to)) {
    params += `from=${from}&to=${to}`
  }
  return params
}

export {
  calculateAggregationInterval,
  calculateVisualRange,
  convertUtcToLocal,
  convertUtcToLocalWithoutZPostfix,
  axios,
  getAccordionState,
  getFirstAccessibleIndex,
  getLocalStorageState,
  setAccordionState,
  setLocalStorageGridState,
  sleep,
  timeRangeToParam,
}
