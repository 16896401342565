import { createSelector } from "@reduxjs/toolkit"

const timeRange = state => state.dashboard.timeRange
const autoRefreshInterval = state => state.dashboard.autoRefresh.interval
const autoRefreshUuid = state => state.dashboard.autoRefresh.uuid
const autoRefreshFreeze = state => state.dashboard.autoRefresh.freeze
const uiDrawerOpen = state => state.dashboard.ui.drawerOpen

const selectTimeRange = createSelector([timeRange], timeRange => timeRange)

const selectAutoRefreshInterval = createSelector(
  [autoRefreshInterval],
  autoRefreshInterval => autoRefreshInterval
)
const selectAutoRefreshUuid = createSelector(
  [autoRefreshUuid],
  autoRefreshUuid => autoRefreshUuid
)
const selectAutoRefreshFreeze = createSelector(
  [autoRefreshFreeze],
  autoRefreshFreeze => autoRefreshFreeze
)

const selectUiDrawerOpen = createSelector(
  [uiDrawerOpen],
  uiDrawerOpen => uiDrawerOpen
)

export {
  selectTimeRange,
  selectAutoRefreshInterval,
  selectAutoRefreshUuid,
  selectAutoRefreshFreeze,
  selectUiDrawerOpen,
}
